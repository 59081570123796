export const AUTH_USER = '/api/auth';
export const GET_SECRETS_MANAGER = 'api/carrierProfile/secret-manager'
export const GET_LOAD_HISTORY = '/api/loadHistory'
export const GET_FACTORING_PARTNERS = '/api/factoringPartners'
export const GET_SERVICE_COSTS_OWNER_OPERATOR = '/api/ownerOperatorServiceCosts'
export const CREATE_ADDITIONAL_CATEGORY = GET_SERVICE_COSTS_OWNER_OPERATOR + '/createAdditionalCosts'
export const GET_SERVICE_COSTS_EFS_TRANSACTION = '/api/efsTransactionRates'
export const REMOVE_ADDITIONAL_SERVICE_COSTS = GET_SERVICE_COSTS_OWNER_OPERATOR + '/removeKey'
export const BUSINESS_INTELLIGENCE = '/api/businessIntelligence'
export const BUSINESS_INTELLIGENCE_HISTORICAL_PERFORMANCE = BUSINESS_INTELLIGENCE + '/getLoadsByRange'
export const BUSINESS_INTELLIGENCE_FINANCIALS = BUSINESS_INTELLIGENCE + '/getFinancials'