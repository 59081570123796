export const FMCSA_VERIFICATION_LINK = '/fmcsaVerification';
export const FEDERAL_SIGNUP_LINK = '/federalSignup'
export const LOGIN_LINK = '/login'
export const SIGNUP_SUPPORT = '/signup/support'
export const ONBOARD_USER_LINK = '/onboard/user'
export const ONBOARDING_USER = ONBOARD_USER_LINK + '/:email';
export const UPDATE_INTEGRATIONS_LINK = '/updateIntegrations';
export const ENHANCED_DASHBOARD = '/enhancedDashboard';
export const FACILITIES_LINK = ENHANCED_DASHBOARD + '/facilities'
export const ERROR_404_LINK = ENHANCED_DASHBOARD + '/404'
export const OPEN_BOARD = '/open_board';
export const MY_BIDS = '/my_bids';
export const MY_LOADS = '/my_loads';
export const INVOICES = '/invoices';
export const WAREHOUSE = '/facilities';
export const USERS = '/users';
export const OWNER_OPERATOR = '/owner_operator';
export const FACTORING_PARTNERS = '/updateFactoring_Partners'
export const SERVICE_COSTS = '/service_costs'
export const EFS_TRANSACTION_COSTS = '/efs'